body,
html {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100%;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
