.login {
  background: url("./assets/images/bg.jpg") no-repeat top center;
  background-size: cover;
  height: 100%;
  min-height: 100vh;

  .content-container {
    width: 100%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  h4 {
    text-align: center;
    margin: 0 0 15px 0;
  }
}
