.error-modal {
  display: block;

  .modal-header {
    background: #dc3545;
    color: map-get($light, "base");
    padding-bottom: 1rem;
  }

  .modal-content {
    border-radius: 6px;
  }

  .close span {
    color: map-get($light, "base");
  }
}
