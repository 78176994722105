@import 'assets/scss/variables';
@import "./components/Header/header.scss";
@import "./screens/Login/login.scss";
@import "./components/ErrorModal/errorModal.scss";
@import "./components/ConfirmationModal/confirmationModal.scss";

body,
html {
  margin: 0;
  background: rgba(0, 0, 0, 0.1);
}

html {
  float: left;
  width: 100%;
  height: auto;
}

body {
  height: 100%;
  min-height: 100vh;
}

#root {
  height: 100%;
  min-height: 100vh;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-image: url("/images/myDPDDesktopApplicationBackground.jpg no-repeat");
  color: white;
}

.App-link {
  color: #61dafb;
}

main {
  height: calc(100% - 100px);
  padding-bottom: 20px;

  .container {
    background: map-get($light, "base");
    border-radius: 8px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);
    padding: 24px;
    height: 100%;
  }
}

h1 {
  line-height: 1;
  font-size: 25px;
  margin: 0;
}

.page-title {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);

  td,
  th {
    padding: 5px 7px;
  }

  tr:hover {
    background: rgba(0, 123, 255, .2);
  }
}

.index {
  height: 100%;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.modal-dialog {
  max-width: 350px;
  margin: 0 auto;
}

.modal-header {
  border: 0;
  padding-bottom: 0;

  .close {
    padding: 12px 16px;
    margin: -24px -24px 0 0;
    font-size: 20px;
    font-weight: 400;

    &:focus {
      outline: none;
    }

    span {
      float: left;
    }
  }
}

.modal-title {
  font-size: 18px;
}

.modal-footer {
  border: 0;
  padding-top: 0;
}

label {
  font-size: 15px;
  line-height: 1;
}

.form-check {
  display: flex;
  align-items: center;
  padding: 0;

  input {
    position: relative;
    margin: 0 6px 0 0;
  }
}

.btn {
  font-size: 14px;
  padding: 5px 10px;
  box-shadow: none;
  border: 0;
  border-radius: 3px;

  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-row {
    margin: 30px 0 0;
  }

  &-delete {
    background: url('./assets/images/delete-icon.svg') no-repeat;
    background-size: 16px;
    padding: 22px;
    background-position: center;
  }
}

.error {
  font-size: 12px;
  color: red;
  margin: 3px 0 0 5px;
}

h4 {
  font-size: 18px;
}

.full-height {
  height: 100%;
  min-height: 100vh;
}

.vertical-center-left {
  display: flex;
  align-items: center;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
