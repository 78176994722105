.confirmation-modal {
  .modal-header {
    background: #007bff;
    color: map-get($light, "base");
    padding-bottom: 1rem;
  }

  .modal-content {
    border-radius: 6px;
  }

  .close span {
    color: map-get($light, "base");
  }
}
