header {
  background: map-get($light, "base");
  margin-bottom: 20px;
  padding: 10px 0 11px;

  .devider {
    margin: -3px 10px 0 0;
  }

  .user-details {
    font-size: 14px;
    margin: 0 10px 0 0;
  }
}
