body,
html {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  height: 100%;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@use "sass:map";
header {
  background: #fff;
  margin-bottom: 20px;
  padding: 10px 0 11px; }
  header .devider {
    margin: -3px 10px 0 0; }
  header .user-details {
    font-size: 14px;
    margin: 0 10px 0 0; }

.login {
  background: url(/static/media/bg.99cd8696.jpg) no-repeat top center;
  background-size: cover;
  height: 100%;
  min-height: 100vh; }
  .login .content-container {
    width: 100%;
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px; }
  .login h4 {
    text-align: center;
    margin: 0 0 15px 0; }

.error-modal {
  display: block; }
  .error-modal .modal-header {
    background: #dc3545;
    color: #fff;
    padding-bottom: 1rem; }
  .error-modal .modal-content {
    border-radius: 6px; }
  .error-modal .close span {
    color: #fff; }

.confirmation-modal .modal-header {
  background: #007bff;
  color: #fff;
  padding-bottom: 1rem; }

.confirmation-modal .modal-content {
  border-radius: 6px; }

.confirmation-modal .close span {
  color: #fff; }

body,
html {
  margin: 0;
  background: rgba(0, 0, 0, 0.1); }

html {
  float: left;
  width: 100%;
  height: auto; }

body {
  height: 100%;
  min-height: 100vh; }

#root {
  height: 100%;
  min-height: 100vh; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-image: url("/images/myDPDDesktopApplicationBackground.jpg no-repeat");
  color: white; }

.App-link {
  color: #61dafb; }

main {
  height: calc(100% - 100px);
  padding-bottom: 20px; }
  main .container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);
    padding: 24px;
    height: 100%; }

h1 {
  line-height: 1;
  font-size: 25px;
  margin: 0; }

.page-title {
  margin-bottom: 20px; }

table {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5); }
  table td,
  table th {
    padding: 5px 7px; }
  table tr:hover {
    background: rgba(0, 123, 255, 0.2); }

.index {
  height: 100%; }

.modal-backdrop {
  background: rgba(0, 0, 0, 0.7); }

.modal-dialog {
  max-width: 350px;
  margin: 0 auto; }

.modal-header {
  border: 0;
  padding-bottom: 0; }
  .modal-header .close {
    padding: 12px 16px;
    margin: -24px -24px 0 0;
    font-size: 20px;
    font-weight: 400; }
    .modal-header .close:focus {
      outline: none; }
    .modal-header .close span {
      float: left; }

.modal-title {
  font-size: 18px; }

.modal-footer {
  border: 0;
  padding-top: 0; }

label {
  font-size: 15px;
  line-height: 1; }

.form-check {
  display: flex;
  align-items: center;
  padding: 0; }
  .form-check input {
    position: relative;
    margin: 0 6px 0 0; }

.btn {
  font-size: 14px;
  padding: 5px 10px;
  box-shadow: none;
  border: 0;
  border-radius: 3px; }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .btn-row {
    margin: 30px 0 0; }
  .btn-delete {
    background: url(/static/media/delete-icon.1400e3d7.svg) no-repeat;
    background-size: 16px;
    padding: 22px;
    background-position: center; }

.error {
  font-size: 12px;
  color: red;
  margin: 3px 0 0 5px; }

h4 {
  font-size: 18px; }

.full-height {
  height: 100%;
  min-height: 100vh; }

.vertical-center-left {
  display: flex;
  align-items: center; }

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

